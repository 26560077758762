import axios from '../../api/config';
import { AUTH_LOADING, CONFIRMATION_ERROR, CONFIRM_SUCCESS } from '../types';

export const confirmEmail = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTH_LOADING });
      const res = await axios.put(`/sales-rep/auth/confirm-email`, {
        token,
      });

      dispatch({ type: CONFIRM_SUCCESS, message: res.data.message });
    } catch (error) {
      dispatch({
        type: CONFIRMATION_ERROR,
        error:
          'Something went wrong, please close this tab and click on the email again.',
      });
    }
  };
};
