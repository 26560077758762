import React from 'react';

import { FaRegCircle, FaRegCheckCircle } from 'react-icons/fa';
import { uidGenerator } from '../../utils/helpers';

const VerticalPricing = ({
  title,
  titleStyle,
  price,
  bulletPoints,
  containerStyle,
  onSelect,
  chosen,
  priceContainerStyle,
  pointStyle,
  bulletPointsContainerStyle,
  priceTextStyle = 'py-4',
  contentContainerStyle,
  index,
}) => {
  return (
    <button
      key={uidGenerator()}
      onClick={onSelect}
      type='button'
      className={
        'w-full flex justify-center items-center bg-lightGreen text-white min-h-[200px] shadow-xl rounded-3xl ' +
        containerStyle
      }
    >
      <div
        className={
          'h-full w-full flex flex-col justify-center items-center' +
          contentContainerStyle
        }
      >
        <div className='h-5/6 mt-6'>
          <div className='w-full flex justify-center items-center'>
            {!chosen ? (
              <FaRegCircle size={25} />
            ) : (
              <FaRegCheckCircle size={25} />
            )}
          </div>
          <hr className='w-4/5 my-4 mx-6' />
          <p className={titleStyle ? titleStyle : 'text-xl mb-4 '}>{title}</p>
          <div className='h-48 overflow-y-auto overflow-hidden'>
            <ul
              className={
                'text-sm w-8/12 pl-7 list-disc text-left justify-start' +
                bulletPointsContainerStyle
              }
            >
              {bulletPoints.map((point) => (
                <li key={point} className={'my-2 ' + pointStyle}>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={
            priceContainerStyle
              ? `w-full text-xl flex justify-center items-center rounded-b-3xl ${priceContainerStyle}`
              : ' h-1/6 w-full text-xl flex justify-center items-center bg-lighterGreen rounded-b-3xl'
          }
        >
          <p className={priceTextStyle}>
            {!isNaN(price) ? '$' + price + '/month' : price}
          </p>
        </div>
      </div>
    </button>
  );
};

export default VerticalPricing;
