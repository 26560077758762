export const COLUMNS = [
  {
    Header: 'Task',
    accessor: 'task',
  },
  {
    Header: 'Points Total',
    accessor: 'pointTotal',
  },
];
