import React from 'react';
import DefaultButton from '../components/common/DefaultButton';
import logo from '../assets/icons/icon.png';
import { MdCheckCircleOutline } from 'react-icons/md';
import colors from '../constants/colors';

const SignupComplete = () => {
  return (
    <div className='bg-gray-200 w-screen h-screen flex justify-center items-center flex-col'>
      <div className='hidden lg:flex lg:w-full justify-center items-center mb-7'>
        <img src={logo} className='w-12 h-12 mx-5 my-5' />
        <span className='font-bold text-5xl sm:text-6xl text-mossGreen'>
          Sales
          <span className='font-bold text-5xl sm:text-6xl text-defaultGreen'>
            Farm
          </span>
        </span>
      </div>
      <div className='w-full h-full lg:w-3/5 lg:h-4/6 mx-auto px-4 sm:px-6 lg:px-8 bg-white flex justify-center items-center flex-col'>
        <div className='lg:hidden mb-36 flex justify-center items-center'>
          <img src={logo} className='w-12 h-12 mx-5 my-5' />
          <span className='font-bold text-5xl sm:text-6xl text-mossGreen'>
            Sales
            <span className='font-bold text-5xl sm:text-6xl text-defaultGreen'>
              Farm
            </span>
          </span>
        </div>
        <MdCheckCircleOutline
          size={60}
          color={colors.mossGreen}
          className='my-4 mr-4'
        />
        <h1 className='text-mossGreen text-3xl md:text-5xl font-bold'>
          Sign up almost complete!
        </h1>
        <h2 className='text-xl mt-4 text-center'>
          Please click the link below to confirm your email address.
        </h2>
        <div className='mt-16 text-center flex justify-center items-center flex-col'>
          <DefaultButton className='w-80 py-3 lg:py-4 lg:px-9' to='/'>
            Confirm Email
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default SignupComplete;
