const tasks = [
  {
    task: 'Called a perspective customer for new business',
    pointTotal: 2,
  },
  {
    task: 'Called a current customer to determine where they are at',
    pointTotal: 2,
  },
  {
    task: 'Called a former customer to check in on them',
    pointTotal: 2,
  },
  {
    task: 'Recieved a solid lead or referral',
    pointTotal: 5,
  },
  {
    task: 'Posted content on social media',
    pointTotal: 10,
  },
  {
    task: 'Recieved a positive Google or Faebook review',
    pointTotal: 10,
  },
  {
    task: 'Submitted an estimate or proposal to a perspective customer',
    pointTotal: 10,
  },
  {
    task: 'Closed a sale',
    pointTotal: 10,
  },
  {
    task: 'Attended a networking event',
    pointTotal: 20,
  },
  {
    task: 'Knocked on doors for an hour',
    pointTotal: 20,
  },
  
];

export default tasks;
