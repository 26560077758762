import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import CompanyLoginPage from './pages/CompanyLoginPage';
import CompanyAccountPage from './pages/CompanyAccountPage';
import SignupComplete from './pages/SignupComplete';
import PaymentPage from './pages/PaymentPage';
import NotFoundPage from './pages/NotFoundPage';
import ConfirmEmailPage from './pages/ConfirmEmailPage';
import CompanyInformationPage from './pages/CompanyInformationPage';
import CompanyMembersPage from './pages/CompanyMembersPage';
import CompanyTasksPage from './pages/CompanyTasksPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import CheckResetCodePage from './pages/CheckResetCodePage';

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<LoginPage />} />
      <Route path='/signup/login-info' element={<CompanyLoginPage />} />
      <Route path='/signup/signup-complete' element={<SignupComplete />} />
      <Route path='/signup/payment-info' element={<PaymentPage />} />
      <Route path='/forgot-password' element={<ForgotPasswordPage />} />
      <Route path='/check-reset-code' element={<CheckResetCodePage />} />
      <Route path='/signup/company-info' element={<CompanyAccountPage />} />
      <Route path='/information' element={<CompanyInformationPage />} />
      <Route path='/members' element={<CompanyMembersPage />} />
      <Route path='/tasks' element={<CompanyTasksPage />} />
      <Route path='/confirm-email/:token' element={<ConfirmEmailPage />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
