import React from 'react';
import logo from '../assets/icons/icon.png';
import DefaultButton from '../components/common/DefaultButton';
import DefaultInput from '../components/common/DefaultInput';

const CompanyAccountPage = () => {
  return (
    <div className='bg-defaultGreen w-screen h-screen flex justify-center items-center'>
      <div className='hidden lg:flex w-5/12 h-full mx-auto text-white bg-mossGreen justify-center items-center flex-col'>
        <h2 className='text-4xl mb-8 font-bold'>Let&rsquo;s Get Started!</h2>
        <p className='text-xl w-3/6 text-center'>
          Welcome to SalesFarm, continue to sign up here.
        </p>
        <img src={logo} className='w-12 h-12 mx-5 my-5' />
      </div>
      <div className='w-full mx-auto h-full px-4 sm:px-6 lg:px-8 bg-white flex justify-center items-center flex-col'>
        <h1 className='text-mossGreen text-3xl md:text-5xl font-bold'>
          Create Company Account
        </h1>
        <h2 className='text-xl mt-4 mb-16'>
          please fill out the required information
        </h2>

        <div className='w-80 md:w-6/12'>
          <DefaultInput
            inputContainerStyle='border-mossGreen border b h-12 my-6'
            placeholder='Company Name'
          />
          <DefaultInput
            inputContainerStyle='border-mossGreen border b h-12 my-6'
            placeholder='Address'
          />
          <DefaultInput
            inputContainerStyle='border-mossGreen border b h-12 my-6'
            placeholder='Phone'
          />
          <DefaultInput
            inputContainerStyle='border-mossGreen border b h-12 my-6'
            placeholder='Website'
          />
        </div>
        <div className='mt-16 text-center flex justify-center items-center flex-col md:flex-row'>
          <DefaultButton
            className='block w-80 h-12 mt-2 mb-2 py-3 md:w-40 lg:hidden lg:py-2 md:mx-5 lg:mx-6 lg:w-56'
            to='/'
          >
            Continue
          </DefaultButton>
          <DefaultButton
            className='w-80 h-12 mt-2 mb-2 py-3 md:w-40 lg:py-2 md:mx-5 lg:mx-6 lg:w-56 bg-white text-mossGreen border border-mossGreen'
            to='/'
          >
            Previous
          </DefaultButton>
          <DefaultButton
            className='hidden w-80 h-12 mt-2 mb-2 py-3 md:w-40 lg:block lg:py-2 md:mx-5 lg:mx-6 lg:w-56'
            to='/'
          >
            Continue
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default CompanyAccountPage;
