import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { confirmEmail } from '../redux/actions/auth-actions';
import BeatLoader from 'react-spinners/BeatLoader';
import Colors from '../constants/colors';

const ConfirmEmailPage = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.authReducer.loading);
  const confirmation_error = useSelector(
    (state) => state.authReducer.confirmation_error
  );
  const successMessage = useSelector(
    (state) => state.authReducer.success_message
  );

  const { token } = useParams();

  useEffect(() => {
    dispatch(confirmEmail(token));
  }, [dispatch, token]);

  return (
    <div className='flex flex-col items-center justify-center py-24 lg:py-12 xl:py-20 md:px-16 px-4'>
      {loading && <BeatLoader size={30} color={Colors.green} />}
      {!loading && !confirmation_error && (
        <>
          <h1 className='text-6xl lg:pt-10 lg:text-7xl font-bold text-green-500 pb-2'>
            Thank You!
          </h1>
          <h2 className='lg:text-5xl md:text-4xl text-2xl font-bold text-gray-900 py-2 max-w-2xl text-center'>
            {successMessage}
          </h2>
          <p className='text-base text-gray-700 py-2 text-center lg:mx-24'>
            You may close this tab
          </p>
        </>
      )}
      {!loading && confirmation_error && (
        <>
          <h1 className='text-6xl lg:pt-10 lg:text-7xl font-bold text-green-500 pb-2'>
            Oops!
          </h1>
          <h2 className='text-2xl font-bold text-gray-900 py-2 max-w-2xl text-center'>
            {confirmation_error}
          </h2>
        </>
      )}
    </div>
  );
};

export default ConfirmEmailPage;
