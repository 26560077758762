import React from 'react';

import Container from '../components/common/Container';
import SortingTable from '../components/table/tasks/SortingTable';

import CreateTaskModal from '../components/modal/CreateTaskModal';

const CompanyTasksPage = () => {
  return (
    <Container>
      <div className='w-full lg:w-4/6 flex justify-center md:justify-start mb-4'>
        <h2 className='bg-pageLightGreen text-mossGreen text-center text-xl pt-2 mt-6 lg:mt-16 mb-0 h-12 w-40 rounded-3xl'>
          Tasks
        </h2>
      </div>

      <div className='w-full lg:w-4/6 flex justify-center md:justify-end mb-4'>
        <CreateTaskModal />
      </div>

      <div className='w-11/12 md:w-auto lg:w-4/6'>
        <label className='flex flex-col mt-4'>
          <span className='text-mossGreen'>Active Members</span>
        </label>
        <div className='overflow-y-scroll md:w-full md:h-100 md:overflow-auto'>
          <SortingTable className='border-2 overflow-scroll w-max md:w-screen lg:w-full text-left' />
        </div>
      </div>
    </Container>
  );
};

export default CompanyTasksPage;
