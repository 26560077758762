const teamMembers = [
  {
    name: 'Gui Nascimento',
    email: 'gui@gmail.com',
    team: 'Team 1',
  },
  {
    name: 'Leo Silva',
    email: 'leo@gmail.com',
    team: 'Team 2',
  },
  {
    name: 'Leo Santos',
    email: 'leoleo@gmail.com',
    team: 'Team 3',
  },
  {
    name: 'John Smith',
    email: 'John@gmail.com',
    team: 'Team 1',
  },
  {
    name: 'Jim Johnson',
    email: 'Jim@gmail.com',
    team: 'Team 2',
  },
  {
    name: 'Logan Khan',
    email: 'Logan@gmail.com',
    team: 'Team 3',
  },
];

export default teamMembers;
