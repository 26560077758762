import React, { useState } from 'react';
import planTypes from '../constants/data/plan-types';
import VerticalPricing from '../components/common/VerticalPricing';
import DefaultButton from '../components/common/DefaultButton';
import colors from '../constants/colors';
import { MdCheckCircle } from 'react-icons/md';

const PaymentPage = () => {
  const [chosenPlan, setChosenPlan] = useState(planTypes[0].name); // Standard

  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [expiration, setExpiration] = useState('');
  const [cvv, setCvv] = useState('');
  const [zip, setZip] = useState('');

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='text-white flex flex-col justify-between items-center lg:w-3/6 h-full lg:h-screen bg-mossGreen'>
        <div className='w-full mb-4 text-center md:text-left p-12'>
          <h1 className='text-4xl font-thin'>Select a Plan</h1>
          <h2>Choose an option for your company</h2>
        </div>
        <div className='flex flex-col md:flex-row mb-4 px-7'>
          {planTypes.map((plan, index) => {
            if (plan.name !== 'Enterprise') {
              return (
                <VerticalPricing
                  key={plan.name}
                  title={plan.name}
                  bulletPoints={plan.bulletPoints}
                  price={plan.price}
                  chosen={plan.name === chosenPlan}
                  onSelect={() => setChosenPlan(plan.name)}
                  index={index}
                  containerStyle='mx-4'
                />
              );
            }
            return;
          })}
        </div>
        <div className='w-full text-center md:text-left'>
          <DefaultButton
            className='w-40 text-center text-white border border-white'
            to='/'
          >
            Previous
          </DefaultButton>
        </div>
      </div>
      <div className='w-3/6 flex flex-col justify-center items-center'>
        <div className='w-full p-2 md:w-3/6'>
          <h2 className='text-mossGreen text-4xl'>Payment &amp; Details</h2>
          <h3 className='text-defaultGray text-2xl'>
            Complete your purchase by providing your payment
          </h3>
        </div>
        <div className='md:mt-20'>
          <form className='flex flex-col w-96'>
            <label className='my-4'>
              <span className='text-mossGreen'>Card Holder Name</span>
              <input
                autoComplete='off'
                placeholder='ROBERT ATKINSON'
                name='text'
                className='outline-none w-full border-black border-b font-bold my-3'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>

            <label className='my-4'>
              <span className='text-mossGreen'>Credit Card</span>
              <input
                autoComplete='off'
                type='number'
                maxLength='16'
                placeholder='5132 1825 4587 2052'
                name='number'
                className='outline-none w-full border-black border-b font-bold my-3'
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                onInput={maxLengthCheck}
              />
            </label>

            <div className='flex'>
              <label className='my-2 mr-10 w-3/6'>
                <span className='text-mossGreen'>Expiration Date</span>
                <input
                  autoComplete='off'
                  type='text'
                  maxLength='5'
                  placeholder='12/24'
                  name='expiration'
                  className='outline-none w-full border-black border-b font-bold my-3'
                  value={expiration}
                  onChange={(e) => setExpiration(e.target.value)}
                  onInput={maxLengthCheck}
                />
              </label>

              <label className='my-2 w-3/6'>
                <span className='text-mossGreen'>Code CVV</span>
                <input
                  autoComplete='off'
                  type='number'
                  maxLength='3'
                  placeholder='834'
                  name='cvv'
                  className='outline-none w-full border-black border-b font-bold my-3'
                  value={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                  onInput={maxLengthCheck}
                />
              </label>
            </div>
            <label className='my-2 w-172'>
              <span className='text-mossGreen'>Zip Code</span>
              <input
                autoComplete='off'
                type='number'
                maxLength='5'
                placeholder='85297'
                name='zip'
                className='outline-none w-full border-black border-b font-bold my-3'
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                onInput={maxLengthCheck}
              />
            </label>
            <DefaultButton
              className='w-full pt-3 h-14 mt-2 mb-2 text-center'
              to='/'
            >
              Submit &amp; Pay
            </DefaultButton>
          </form>
        </div>

        <div className='flex items-center'>
          <MdCheckCircle
            size={15}
            color={colors.mossGreen}
            className='my-4 mr-4'
          />
          <p className='text-defaultGray font-bold'>
            <span className='text-mossGreen font-normal'>Powered by</span>{' '}
            stripe
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
