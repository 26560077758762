import React, { useState } from 'react';
import { XIcon } from '@heroicons/react/outline';

const Modal = ({ className }) => {
  const [modal, setModal] = useState(false);

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <div>
      <button
        onClick={handleModal}
        className={
          'w-44 px-8 py-1 bg-mossGreen text-white rounded-md font-medium text-lg ' +
          className
        }
      >
        + Create Team
      </button>

      {modal && (
        <div className='inset-0 fixed z-10 text-left'>
          <div
            className='inset-0 fixed z-10 bg-black opacity-30'
            onClick={handleModal}
          ></div>
          <div className='absolute w-full top-2/4 left-2/4 translate-x-50 translate-y-50 leading-normal bg-white py-4 px-7 border border-mossGreen lg:w-650 max-w-xl min-w-min z-50'>
            <h2 className='text-4xl text-mossGreen'>Create a New Task:</h2>
            <label className='flex flex-col mt-4'>
              <span className='text-mossGreen'>Task Title</span>
              <input
                autoComplete='off'
                placeholder='Called a perspective customer for new business'
                name='name'
                className='outline-none border-mossGreen border h-12 font-bold my-3 px-2'
                value=''
                onChange={(e) => setName(e.target.value)}
              />
            </label>
            <label className='flex flex-col my-4'>
              <span className='text-mossGreen'>Points</span>
              <select className='w-20 h-12 p-2 border border-mossGreen'>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='20'>20</option>
              </select>
            </label>
            <button
              onClick={handleModal}
              className={
                'py-1 w-36 bg-pageLightGreen text-mossGreen my-4 mr-6 font-medium text-lg ' +
                className
              }
            >
              Cancel
            </button>
            <button
              className={
                'py-1 w-36 bg-mossGreen text-white font-medium text-lg ' +
                className
              }
            >
              + Add New
            </button>
            <div className='absolute top-0 right-0 mr-2 pt-2'>
              <button
                type='button'
                className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                onClick={handleModal}
              >
                <XIcon className='h-6 w-6 text-black' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
