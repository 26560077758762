import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';

import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

import tasks from '../../../constants/data/tasks';
import { COLUMNS } from './columns';

const SortingTable = ({ className }) => {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => tasks, []);

  const tableInstance = useTable({ columns, data }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <table className={' ' + className} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            className={'text-mossGreen border-0 ' + className}
            key={data.id}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              <th
                key={data.id}
                className='text-sm font-normal px-6 pb-2 pt-6'
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FiChevronDown className='inline ml-2' />
                    ) : (
                      <FiChevronUp className='inline ml-2' />
                    )
                  ) : (
                    ''
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr key={data.id} {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    key={data.id}
                    className='px-6 py-2 border-b-2'
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SortingTable;
