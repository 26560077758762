import React from 'react';

const DefaultInput = ({ placeholder, inputStyle, inputContainerStyle }) => {
  return (
    <div
      className={
        'border border-mossGreen outline-none w-full ' + inputContainerStyle
      }
    >
      <input
        className={
          'outline-none text-lg text-darkGray p-2 w-full ' + inputStyle
        }
        placeholder={placeholder}
      />
    </div>
  );
};

export default DefaultInput;
