import React, { useState } from 'react';
import Container from '../components/common/Container';
import DefaultButton from '../components/common/DefaultButton';

const CompanyInformationPage = () => {
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Container>
      <div className='w-4/6 flex justify-start'>
        <h2 className='bg-pageLightGreen text-mossGreen text-center text-xl pt-2 mt-16 mb-0 h-12 w-40 rounded-3xl'>
          Information
        </h2>
      </div>

      <div className='flex justify-center md:w-screen lg:w-full'>
        <form className='flex flex-col md:flex-row w-4/6 mt-12'>
          <div className='w-full'>
            <label className='flex flex-col mt-4'>
              <span className='text-mossGreen'>Company Name</span>
              <input
                autoComplete='off'
                placeholder='Sales Santos'
                name='companyName'
                className='outline-none w-11/12 border-black border h-12 font-bold my-3 px-2'
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </label>

            <label className='flex flex-col mt-4'>
              <span className='text-mossGreen'>Address</span>
              <input
                autoComplete='off'
                type='text'
                placeholder='26 South 2nd West'
                name='address'
                className='outline-none w-11/12 border-black border h-10 font-bold my-3 px-2'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </label>

            <div className='flex'>
              <input
                autoComplete='off'
                type='text'
                placeholder='Rexburg'
                name='city'
                className='my-2 mr-2 w-45 outline-none border-black border h-10 font-bold px-2'
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />

              <input
                autoComplete='off'
                type='text'
                placeholder='Idaho'
                name='state'
                className='my-2 w-45 outline-none border-black border h-10 font-bold px-2'
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>

            <div className='flex'>
              <input
                autoComplete='off'
                type='number'
                maxLength='5'
                placeholder='83440'
                name='zip'
                className='my-2 mr-10 w-45 outline-none border-black border h-10 font-bold px-2'
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </div>

            <label className='flex flex-col my-2'>
              <span className='text-mossGreen'>Phone</span>
              <input
                autoComplete='off'
                type='number'
                placeholder='480 2480 2686'
                name='phone'
                className='outline-none w-11/12 border-black border h-10 font-bold my-3 px-2'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </label>
          </div>
          <div className='flex flex-col w-full'>
            <label className='flex flex-col mt-4'>
              <span className='text-mossGreen'>Website</span>
              <input
                autoComplete='off'
                placeholder='salesfarm.com'
                name='website'
                className='outline-none w-11/12 border-black border h-12 font-bold my-3 px-2'
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </label>

            <label className='flex flex-col mt-4'>
              <span className='text-mossGreen'>Company Email</span>
              <input
                autoComplete='off'
                type='text'
                placeholder='26 South 2nd West'
                name='Company Email'
                className='outline-none w-11/12 border-black border h-10 font-bold my-3 px-2'
                value={companyEmail}
                onChange={(e) => setCompanyEmail(e.target.value)}
              />
            </label>

            <label className='flex flex-col my-2'>
              <span className='text-mossGreen'>Password</span>
              <input
                autoComplete='off'
                type='text'
                placeholder='***********'
                name='password'
                className='outline-none w-11/12 border-black border h-10 font-bold my-3 px-2'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            <div className='text-right mb-8 mt-negative16'>
              <DefaultButton
                className='text-sm mx-4 bg-transparent text-mossGreen'
                to='/'
              >
                Update Password
              </DefaultButton>
            </div>

            <div className='text-right my-2 w-11/12'>
              <DefaultButton
                className='w-3/6 py-2 mt-2 mb-2 text-center text-xl'
                to='/'
              >
                Edit Account
              </DefaultButton>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default CompanyInformationPage;
