import { AUTH_LOADING, CONFIRMATION_ERROR, CONFIRM_SUCCESS } from '../types';

const initialState = {
  loading: false,
  confirmation_error: undefined,
  success_message: undefined,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRMATION_ERROR:
      return {
        ...state,
        confirmation_error: action.error,
        success_message: undefined,
        loading: false,
      };
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CONFIRM_SUCCESS:
      return {
        ...state,
        loading: false,
        confirmation_error: undefined,
        success_message: action.message,
      };
    default:
      return state;
  }
};

export default authReducer;
