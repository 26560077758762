import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import logo from '../assets/icons/icon.png';
import colors from '../constants/colors';
import DefaultButton from '../components/common/DefaultButton';

const CheckResetCodePage = () => {
  return (
    <div className='bg-defaultGreen w-screen h-screen flex flex-col justify-center items-center'>
      <div className='w-11/12 md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-xl border border-mossGreen shadow-2xl'>
        <div className='max-w-3xl mx-auto'>
          <div className='w-full flex flex-col justify-center items-center my-10'>
            <img src={logo} className='w-12 h-12 my-5' />
            <span className='font-bold text-5xl sm:text-6xl text-mossGreen'>
              Check Reset Code
            </span>
          </div>
          <div className='flex items-center justify-center flex-row w-full'>
            <div className='flex items-center flex-row w-full sm:w-6/12'>
              <AiOutlineCheckCircle
                size={30}
                color={colors.mossGreen}
                className='my-4 mr-4'
              />
              <input
                placeholder='Enter Code'
                className='outline-none w-full border-black border-b'
              />
            </div>
          </div>
          <div className='flex items-center justify-center flex-row w-full'>
            <div className='flex items-center flex-row w-full sm:w-6/12'></div>
          </div>
          <div className='flex items-center justify-center flex-row w-ful my-12'>
            <div className='flex items-center flex-row w-full sm:w-6/12 justify-center'>
              <DefaultButton to='/login' className='px-20'>
                Check Code
              </DefaultButton>
            </div>
          </div>
        </div>
        {/* Learn more section */}
        <div className='flex items-center justify-center flex-row w-ful my-12'>
          <div className='flex items-center flex-col sm:flex-row w-full sm:w-6/12 justify-between'>
            <Link to='/' className='font-normal text-mossGreen text-sm'>
              Not a member?{' '}
              <span className='font-normal text-defaultGreen text-sm pb-4 sm:mb-0'>
                Join Now
              </span>
            </Link>
            <Link to='/' className='font-normal text-defaultGreen text-sm'>
              Learn more about Sales Farm
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckResetCodePage;
