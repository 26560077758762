// export default Container;
import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

import logo from '../../assets/icons/icon.png';

const navigation = [
  { name: 'Members', href: '/members', current: false },
  { name: 'Tasks', href: '/tasks', current: false },
  { name: 'Information', href: '/information', current: true },
  { name: 'Billing', href: '/billing', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const Container = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 flex z-40 md:hidden'
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-limeGreen bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-mossGreen'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                  <button
                    type='button'
                    className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-mossGreen'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>
              <div className='flex-shrink-0 flex items-center px-4'>
                <img className='h-8 w-auto' src={logo} alt='Sales Farm' />
                <p className='text-white mx-4 text-4xl'>Sales Farm</p>
              </div>
              <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                <nav className='flex-1 px-8 py-6 text-center'>
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-mossGreen text-white'
                          : 'text-white hover:opacity-90 opacity-30',
                        'group flex items-center text-left w-5/6 px-2 py-4 text-xl font-medium rounded-md'
                      )}
                    >
                      <div className='border-b-2 w-full border-white'>
                        {item.name}
                      </div>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14' aria-hidden='true'>
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden md:flex md:w-96 md:flex-col md:fixed md:inset-y-0'>
        <div className='flex-1 flex flex-col min-h-0 bg-lightGray'>
          <div className='flex items-center h-24 flex-shrink-0 px-8 font-bold bg-mossGreen'>
            <img src={logo} className='w-12 h-12 mx-5 my-5' />
            <p className='text-white text-4xl'>Sales Farm</p>
          </div>
          <div className='flex flex-col items-center justify-center bg-white border-b-2 border-solid border-b-lightGray w-full h-24 px-10 border'>
            <p className='w-full text-2xl text-mossGreen'>Account</p>
          </div>
          <div className='flex-1 flex flex-col'>
            <nav className='flex-1 px-8 py-6 text-center'>
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-lightGray text-lightGreen'
                      : 'text-mossGreen hover:bg-lightGray hover:opacity-90 opacity-30',
                    'group flex items-center text-left w-5/6 px-2 py-4 text-xl font-medium rounded-md'
                  )}
                >
                  <div className='border-b-2 w-full border-mossGreen'>
                    {item.name}
                  </div>
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className='md:pl-96 flex flex-col'>
        <div className='sticky w-screen top-0 z-10 flex-shrink-0 flex h-24 bg-mossGreen shadow'>
          <button
            type='button'
            className='px-4 border-r border-gray-200 text-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open sidebar</span>
            <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div className='flex flex-col justify-center bg-white border-b-2 border-solid border-b-lightGray h-24'>
          <p className='w-full text-4xl text-center md:text-left md:ml-48 text-mossGreen'>
            Welcome, SalesSantos
          </p>
        </div>
        <main className='h-full flex justify-center items-center flex-col md:w-screen lg:w-full'>
          {children}
        </main>
      </div>
    </div>
  );
};

export default Container;
