import React, { useState } from 'react';

import DefaultButton from '../common/DefaultButton';
import CreateTeamModal from './CreateTeamModal';

import { XIcon } from '@heroicons/react/outline';

const Modal = ({ className }) => {
  const [modal, setModal] = useState(false);
  const [findTeam, setFindTeam] = useState('');

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <div className='mt-auto'>
      <button
        onClick={handleModal}
        className={
          'w-50 px-8 bg-mossGreen rounded-md text-sm font-normal py-0 bg-transparent text-mossGreen ' +
          className
        }
      >
        + View All Team Codes
      </button>

      {modal && (
        <div className='inset-0 fixed z-10 text-left'>
          <div
            className='inset-0 fixed z-10 bg-black opacity-30'
            onClick={handleModal}
          ></div>
          <div className='absolute top-2/4 left-2/4 translate-x-50 translate-y-50 leading-normal bg-white py-4 px-7 border border-mossGreen w-full h-100 lg:w-4/5 max-w-2xl min-w-min z-50'>
            <h2 className='text-4xl text-mossGreen'>Your Team Codes:</h2>
            <input
              autoComplete='off'
              placeholder='Find Team'
              name='name'
              className='outline-none w-4/5 rounded-full border-mossGreen border h-8 font-bold my-3 px-2'
              value={findTeam}
              onChange={(e) => setFindTeam(e.target.value)}
            />
            <div className='flex flex-col flex-wrap md:flex-row'>
              <DefaultButton
                className='px-0 w-44 mt-5 md:mr-6 bg-white text-mossGreen border border-mossGreen text-center'
                to='/'
              >
                Team 1 Code: HGIYH
              </DefaultButton>
              <DefaultButton
                className='px-0 w-44 mt-5 md:mr-6 bg-white text-mossGreen border border-mossGreen text-center'
                to='/'
              >
                Team 1 Code: HGIYH
              </DefaultButton>
              <DefaultButton
                className='px-0 w-44 mt-5 md:mr-6 bg-white text-mossGreen border border-mossGreen text-center'
                to='/'
              >
                Team 1 Code: HGIYH
              </DefaultButton>
              <DefaultButton
                className='px-0 w-44 mt-5 md:mr-6 bg-white text-mossGreen border border-mossGreen text-center'
                to='/'
              >
                Team 1 Code: HGIYH
              </DefaultButton>
              <DefaultButton
                className='px-0 w-44 mt-5 md:mr-6 bg-white text-mossGreen border border-mossGreen text-center'
                to='/'
              >
                Team 1 Code: HGIYH
              </DefaultButton>
            </div>
            <CreateTeamModal className='w-50 my-4 bg-mossGreen rounded-md text-md font-normal py-0 bg-transparent text-mossGreen' />

            <div className='absolute top-0 right-0 mr-2 pt-2'>
              <button
                type='button'
                className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                onClick={handleModal}
              >
                <XIcon className='h-6 w-6 text-black' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
