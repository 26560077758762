import React from 'react';

import Container from '../components/common/Container';
import DefaultButton from '../components/common/DefaultButton';
import SortingTable from '../components/table/members/SortingTable';

import CreateTeamModal from '../components/modal/CreateTeamModal';
import ViewAllTeamsModal from '../components/modal/ViewAllTeamsModal';

const CompanyMembersPage = () => {
  return (
    <Container>
      <div className='w-full lg:w-4/6 flex justify-center md:justify-start mb-4'>
        <h2 className='bg-pageLightGreen text-mossGreen text-center text-xl pt-2 mt-6 lg:mt-16 mb-0 h-12 w-40 rounded-3xl'>
          Members
        </h2>
      </div>

      <div className='w-full lg:w-4/6 flex justify-center md:justify-end mb-4'>
        <CreateTeamModal  />
      </div>

      <div className='w-full lg:w-4/6 flex flex-col md:flex-row justify-end items-center'>
        <ViewAllTeamsModal />

        <DefaultButton
          className='px-0 w-44 mt-8 md:mr-6 bg-white text-mossGreen border border-mossGreen text-center'
          to='/'
        >
          Team 1 Code: HGIYH
        </DefaultButton>

        <DefaultButton
          className='px-0 w-44 mt-8 bg-white text-mossGreen border border-mossGreen text-center'
          to='/'
        >
          Team 2 Code: HGIYP
        </DefaultButton>
      </div>

      <div className='w-11/12 md:w-auto lg:w-4/6'>
        <label className='flex flex-col mt-4'>
          <span className='text-mossGreen'>Active Members</span>
        </label>
        <div className='overflow-x-scroll md:w-full md:h-screen md:overflow-auto'>
          <SortingTable className='border-2 w-max md:w-screen lg:w-full text-left' />
        </div>
      </div>
    </Container>
  );
};

export default CompanyMembersPage;
