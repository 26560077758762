const planTypes = [
  {
    name: 'Standard',
    price: 19,
    bulletPoints: [
      'Best for small businesses just getting started.',
      'User Limit 3.',
    ],
  },
  {
    name: 'Professional',
    price: 29,
    bulletPoints: [
      'Ideal for businesses looking to work smarter.',
      'User Limit 10.',
      'Dedicated Success Manager.',
    ],
  },
  {
    name: 'Premium',
    price: 39,
    bulletPoints: [
      'Advanced features for growing businesses',
      'User Limit 25',
      'Priority Support',
      'Dedicated Success Manager',
      'Custom Training',
      'Custom Training',
      'Custom Training',
      'Custom Training',
      'Custom Training',
      'Custom Training',
      'Custom Training',
    ],
  },
];

export default planTypes;
