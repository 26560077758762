import React from 'react';
import { Link } from 'react-router-dom';
import DefaultButton from '../components/common/DefaultButton';
import DefaultInput from '../components/common/DefaultInput';

const CompanyLoginPage = () => {
  return (
    <div className='bg-defaultGreen w-screen h-screen flex justify-center items-center'>
      <div className='hidden lg:flex w-5/12 h-full mx-auto text-white bg-mossGreen justify-center items-center flex-col'>
        <h2 className='text-6xl mb-8 font-bold'>Welcome!</h2>
        <Link to='/' className='font-bold mb-3'>
          Already a member?
        </Link>
        <p className='text-xl w-3/6 text-center'>
          To continue to connect with your team, please log in below.
        </p>
        <DefaultButton
          className='px-14 py-1 m-1 lg:m-5 bg-white text-mossGreen border border-mossGreen'
          to='/'
        >
          Login
        </DefaultButton>
      </div>
      <div className='w-full mx-auto h-full px-4 sm:px-6 lg:px-8 bg-white flex justify-center items-center flex-col'>
        <h1 className='text-mossGreen text-3xl md:text-5xl font-bold'>
          Create Company Login
        </h1>
        <h2 className='text-xl mt-4 mb-16'>
          use your company email to sign up
        </h2>

        <div className='w-80 md:w-6/12 lg:w-4/12'>
          <DefaultInput
          
            inputContainerStyle='border-mossGreen border b h-12 my-6'
            placeholder='Email'
          />
          <DefaultInput
            inputContainerStyle='border-mossGreen border b h-12 my-6'
            placeholder='Password'
          />
          <DefaultInput
            inputContainerStyle='border-mossGreen border b h-12 my-6'
            placeholder='Confirm Password'
          />
        </div>
        <div className='mt-16 text-center flex justify-center items-center flex-col'>
          <DefaultButton className='w-80 lg:w-fit py-3 lg:py-2 lg:px-9' to='/'>
            Create Account
          </DefaultButton>

          <DefaultButton
            className='w-80 py-3 m-2 lg:py-1 lg:m-5 bg-white text-mossGreen border border-mossGreen lg:hidden'
            to='/'
          >
            Already Signed up? Login
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default CompanyLoginPage;
